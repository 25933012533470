/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import "assets/Style.css";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import routes from "routes";

// Image
import bgImage from "assets/images/illustrations/illustration-reset.jpg";
// import { Typography } from "@mui/material";

function ServiceErp() {
  return (
    <>
      <MKBox top=".5rem" width="100%">
        <DefaultNavbar routes={routes} transparent light />
      </MKBox>
      <MKBox
        minHeight="55vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center", marginTop: "15px" }}
          >
            <MKTypography
              variant="h3"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Transform Your Business Operations with
              <br />
              Our Cutting-Edge ERP Software!
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -7,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" py={8} px={20}>
          <Container>
            <Grid container alignItems="center">
              <MKTypography variant="body1" textAlign="center" opacity={0.8} mt={1} mb={3}>
                Revolutionize the way you manage your business processes with our comprehensive
                Enterprise Resource Planning (ERP) software. Tailored to meet the unique needs of
                businesses, our ERP solution is a powerful tool that integrates key functions,
                enhances efficiency, and propels your organization toward greater success.
              </MKTypography>
              <Grid item xs={12} lg={12}>
                <Grid container justifyContent="flex-start">
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="data_object"
                        title="Unified Data Management"
                        description="Say goodbye to data silos. Our ERP software seamlessly integrates all your business functions into a centralized system, ensuring real-time access to critical information for better decision-making."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="construction"
                        title="Improved Efficiency"
                        description="Streamline your workflows and boost productivity. Our ERP automates routine tasks, eliminates manual processes, and facilitates smooth collaboration across departments, leading to increased operational efficiency."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="support_agent"
                        title="Enhanced Customer Service"
                        description="Deliver exceptional customer experiences with our CRM (Customer Relationship Management) integration. Access customer data, track interactions, and tailor your services for heightened customer satisfaction and loyalty."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="price_check"
                        title="Accurate Financial Management"
                        description="Gain control over your finances with our robust financial management modules. From budgeting to reporting, our ERP ensures accurate and transparent financial data, fostering a sound financial foundation."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="assessment"
                        title="Inventory Optimization"
                        description="Optimize your inventory levels and reduce costs. Our ERP software provides real-time visibility into inventory, allowing you to make informed decisions, prevent stockouts, and improve overall supply chain management."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="adjust"
                        title="Customizable Solutions"
                        description="Every business is unique, and our ERP software adapts to your specific requirements. Enjoy a tailor-made solution that aligns perfectly with your organizational structure, industry, and growth objectives."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="fast_forward"
                        title="Real-Time Analytics"
                        description="Harness the power of data with our real-time analytics tools. Gain valuable insights into your business performance, identify trends, and make informed decisions for strategic planning and continuous improvement."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="scale"
                        title="Scalability"
                        description="Whether you're a small startup or a large enterprise, our ERP software grows with you. Scale your usage as your business expands, ensuring you always have the tools you need to support your evolving needs."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                </Grid>
              </Grid>
              <MKTypography variant="body1" textAlign="center" opacity={0.8} mt={1} mb={3}>
                Embrace the future of business management with our user-friendly and efficient ERP
                software. Let us empower your organization to thrive by optimizing processes,
                increasing collaboration, and driving overall operational excellence.
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
      </Card>
    </>
  );
}

export default ServiceErp;
