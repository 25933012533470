/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import "assets/Style.css";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import routes from "routes";

// Image
import bgImage from "assets/images/illustrations/illustration-reset.jpg";
// import { Typography } from "@mui/material";

function ServiceEpfEsic() {
  return (
    <>
      <MKBox top=".5rem" width="100%">
        <DefaultNavbar routes={routes} transparent light />
      </MKBox>
      <MKBox
        minHeight="55vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center", marginTop: "15px" }}
          >
            <MKTypography
              variant="h3"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Streamline Your Workforce Benefits with Our
              <br />
              ESIC and EPF Management Services!
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -7,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" py={8} px={20}>
          <Container>
            <Grid container alignItems="center">
              <MKTypography variant="body1" textAlign="center" opacity={0.8} mt={1} mb={3}>
                Navigating the complexities of ESIC (Employee State Insurance Corporation) and EPF
                (Employee Provident Fund) compliance can be a daunting task for businesses.
                That&apos;s where we step in to simplify and optimize the entire process, ensuring
                seamless management of your workforce benefits.
              </MKTypography>
              <Grid item xs={12} lg={12}>
                <Grid container justifyContent="flex-start">
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="all_inclusive"
                        title="Comprehensive Compliance"
                        description="Stay on the right side of the law by entrusting us with the intricate details of ESIC and EPF regulations. We ensure your business is fully compliant, minimizing the risk of legal issues and fines."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="flag"
                        title="Efficient Administration"
                        description="Our streamlined processes and dedicated experts make ESIC and EPF administration a breeze. From enrollment to documentation, we handle it all, allowing you to focus on your core business operations."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="calendar_month"
                        title="Timely Contributions"
                        description="Avoid penalties and delays by entrusting us with the timely and accurate submission of ESIC and EPF contributions. We ensure that your employees' financial security is never compromised."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="assistant"
                        title="Employee Support"
                        description="We understand the importance of a satisfied workforce. Our services include assisting your employees with queries, claims, and other ESIC and EPF-related matters, fostering a positive and supportive work environment."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="ballot"
                        title="Customized Solutions"
                        description="Every business is unique, and our services are tailored to meet your specific needs. Whether you're a small startup or a large enterprise, our flexible solutions adapt to your scale and requirements."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="computer"
                        title="Technological Integration"
                        description="Embrace the convenience of technology with our integrated systems. Experience hassle-free data management, automated processes, and real-time updates, enhancing the efficiency of your ESIC and EPF management."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="payments"
                        title="Cost-Efficiency"
                        description="Our services not only save you time but also contribute to cost-effectiveness. By outsourcing your ESIC and EPF management to us, you can redirect resources towards your core business activities."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                </Grid>
              </Grid>
              <MKTypography variant="body1" textAlign="center" opacity={0.8} mt={1} mb={3}>
                Partner with us to transform ESIC and EPF management from a challenge to a strategic
                advantage for your business. Let us handle the complexities, allowing you to focus
                on what you do best - driving your business forward!
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
      </Card>
    </>
  );
}

export default ServiceEpfEsic;
