/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import "assets/Style.css";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import routes from "routes";

// Image
import bgImage from "assets/images/illustrations/illustration-reset.jpg";
// import { Typography } from "@mui/material";

function ServiceAccFin() {
  return (
    <>
      <MKBox top=".5rem" width="100%">
        <DefaultNavbar routes={routes} transparent light />
      </MKBox>
      <MKBox
        minHeight="55vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center", marginTop: "15px" }}
          >
            <MKTypography
              variant="h3"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Empower Your Business with Expert
              <br />
              Financial and Accounting Services!
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -7,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" py={8} px={20}>
          <Container>
            <Grid container alignItems="center">
              <MKTypography variant="body1" textAlign="center" opacity={0.8} mt={1} mb={3}>
                Navigating the intricacies of financial management and accounting is essential for
                sustained business success. Our dedicated financial and accounting services are
                designed to be your trusted partner in achieving financial stability, compliance,
                and strategic growth.
              </MKTypography>
              <Grid item xs={12} lg={12}>
                <Grid container justifyContent="flex-start">
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="calculate"
                        title="Strategic Financial Planning"
                        description="Unlock the potential of your business with our strategic financial planning services. From budgeting to forecasting, we help you make informed decisions that drive long-term success."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="folder_special"
                        title="Accurate Bookkeeping"
                        description="Say goodbye to financial discrepancies. Our meticulous bookkeeping services ensure that your financial records are accurate, organized, and in compliance with regulatory standards."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="price_check"
                        title="Tax Compliance"
                        description="Navigate the complexities of taxation with confidence. Our experts stay abreast of ever-changing tax laws, optimizing your tax strategy to maximize savings and ensure compliance with all regulatory requirements."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="money"
                        title="Cost Management"
                        description="Streamline your expenses and enhance profitability with our cost management solutions. We analyze your financial data to identify cost-saving opportunities, driving efficiency throughout your business operations."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="assessment"
                        title="Financial Reporting"
                        description="Gain valuable insights into your business performance with our comprehensive financial reporting services. Customized reports provide a clear overview, empowering you to make data-driven decisions for future growth."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="currency_rupee"
                        title="Payroll and Benefits Administration"
                        description="Simplify payroll and benefits administration with our integrated solutions. From accurate payroll processing to managing employee benefits, we ensure a smooth and compliant process."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="scale"
                        title="Risk Management"
                        description="Mitigate financial risks with our proactive risk management services. We assess potential risks, develop strategies to address them, and safeguard your business against unforeseen challenges."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="assured_workload"
                        title="Compliance Assurance"
                        description="Stay on the right side of regulatory requirements. Our team ensures that your financial and accounting practices adhere to the latest standards, minimizing the risk of penalties and legal complications."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                </Grid>
              </Grid>
              <MKTypography variant="body1" textAlign="center" opacity={0.8} mt={1} mb={3}>
                Partner with us to transform your financial and accounting processes into strategic
                assets for your business. Let our experienced professionals handle the numbers,
                allowing you to focus on driving innovation, expansion, and overall business
                success.
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
      </Card>
    </>
  );
}

export default ServiceAccFin;
