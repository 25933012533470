/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
// import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

function Information() {
  return (
    <Container>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, md: 8, lg: 12 }}>
        <Grid item xs={4} md={4} lg={4}>
          <RotatingCard>
            <RotatingCardFront
              image={bgFront}
              icon="fingerprint"
              title={
                <>
                  EPF & ESIC <br /> Services
                </>
              }
              description="Streamline Your Workforce Benefits with Our ESIC and EPF Management Services!"
            />
            <RotatingCardBack
              image={bgBack}
              title="EPF & ESIC Compliance"
              description="EPF and ESIC compliance are crucial aspects for businesses, especially in countries like India where these regulations are enforced. Both EPF and ESIC are social security schemes designed to provide financial protection and benefits to employees."
              action={{
                type: "internal",
                route: "/services/epf-esic",
                label: "Read more",
              }}
            />
          </RotatingCard>
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <RotatingCard>
            <RotatingCardFront
              image={bgFront}
              icon="groups"
              title={
                <>
                  HRMS
                  <br />
                  Software
                </>
              }
              description="Empower Your Business with Our Cutting-Edge HRMS Software Solutions!"
            />
            <RotatingCardBack
              image={bgBack}
              title="Human Resource Management System"
              description="Unlock the full potential of your workforce and streamline your HR processes with our state-of-the-art Human Resource Management Systems. We specialize in delivering innovative software solutions to meet the unique needs of businesses, revolutionizing the way you manage your human capital."
              action={{
                type: "internal",
                route: "/services/hrms",
                label: "Read more",
              }}
            />
          </RotatingCard>
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <RotatingCard>
            <RotatingCardFront
              image={bgFront}
              icon="payments"
              title={
                <>
                  Payroll
                  <br />
                  Services
                </>
              }
              description="Simplify Your Payroll, Elevate Your Business: Choose Our Expert Payroll Services!"
            />
            <RotatingCardBack
              image={bgBack}
              title="Payroll Services"
              description="Managing payroll can be intricate and time-consuming, diverting your attention from core business activities. Our dedicated payroll services are tailored to alleviate this burden, providing seamless solutions that enhance accuracy, compliance, and overall operational efficiency."
              action={{
                type: "internal",
                route: "/services/payroll",
                label: "Read more",
              }}
            />
          </RotatingCard>
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <RotatingCard>
            <RotatingCardFront
              image={bgFront}
              icon="calculate"
              title={
                <>
                  Financial & Accounting
                  <br />
                  Services
                </>
              }
              description="Empower Your Business with Expert Financial and Accounting Services!"
            />
            <RotatingCardBack
              image={bgBack}
              title="Financial & Accounting Services"
              description="Navigating the intricacies of financial management and accounting is essential for sustained business success. Our dedicated financial and accounting services are designed to be your trusted partner in achieving financial stability, compliance, and strategic growth."
              action={{
                type: "internal",
                route: "/services/account&finance",
                label: "Read more",
              }}
            />
          </RotatingCard>
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <RotatingCard>
            <RotatingCardFront
              image={bgFront}
              icon="rocket"
              title={
                <>
                  ERP
                  <br />
                  Software
                </>
              }
              description="Transform Your Business Operations with Our Cutting-Edge ERP Software!"
            />
            <RotatingCardBack
              image={bgBack}
              title="Enterprise Resource Planning"
              description="Revolutionize the way you manage your business processes with our comprehensive ERP softwares. Tailored to meet the unique needs of businesses, our ERP solution is a powerful tool that integrates key functions, enhances efficiency and propels your organization toward greater success."
              action={{
                type: "internal",
                route: "/services/erp",
                label: "Read more",
              }}
            />
          </RotatingCard>
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <RotatingCard>
            <RotatingCardFront
              image={bgFront}
              icon="touch_app"
              title={
                <>
                  Contact us for a
                  <br />
                  Demonstration
                </>
              }
              description="Unlock the Power of Innovation: Request a Personalized Demo Today!"
            />
            <RotatingCardBack
              image={bgBack}
              title="First Class Services is what you get"
              description="Our implementation process includes: Pre-implementation Discussion, Data Migration, Training, Post-implementation discussion, Hand-hold support until implementation goes live."
              action={{
                type: "internal",
                route: "/contact-us",
                label: "Book a Demo",
              }}
            />
          </RotatingCard>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Information;
