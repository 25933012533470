/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import "assets/Style.css";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import routes from "routes";

// Image
import bgImage from "assets/images/illustrations/illustration-reset.jpg";
// import { Typography } from "@mui/material";

function ServiceHRMS() {
  return (
    <>
      <MKBox top=".5rem" width="100%">
        <DefaultNavbar routes={routes} transparent light />
      </MKBox>
      <MKBox
        minHeight="55vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center", marginTop: "15px" }}
          >
            <MKTypography
              variant="h3"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Empower Your Business with Our
              <br />
              Cutting-Edge HRMS Software Solutions!
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -7,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" py={8} px={20}>
          <Container>
            <Grid container alignItems="center">
              <MKTypography variant="body1" textAlign="center" opacity={0.8} mt={1} mb={3}>
                Unlock the full potential of your workforce and streamline your HR processes with
                our state-of-the-art Human Resource Management System (HRMS). We specialize in
                delivering innovative software solutions tailored to meet the unique needs of
                businesses, revolutionizing the way you manage your human capital.
              </MKTypography>
              <Grid item xs={12} lg={12}>
                <Grid container justifyContent="flex-start">
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="auto_mode"
                        title="Effortless Automation"
                        description="Say goodbye to manual, time-consuming HR tasks. Our HRMS automates processes such as payroll, attendance tracking, and leave management, boosting efficiency and minimizing errors."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="data_object"
                        title="Centralized Data Management"
                        description="Consolidate all your HR data in one secure, centralized platform. Easily access employee records, performance metrics, and compliance information, fostering better decision-making and strategic planning."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="self_improvement"
                        title="Employee Self-Service"
                        description="Empower your employees with self-service features, allowing them to update personal information, submit leave requests, and access relevant documents with ease. Enhance engagement and satisfaction within your workforce."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="assistant"
                        title="Comprehensive Recruitment Tools"
                        description="Simplify your hiring process with our robust recruitment modules. Post jobs, track applications, and manage candidate profiles seamlessly, ensuring you attract and onboard the best talent effortlessly."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="ballot"
                        title="Performance Management"
                        description="Foster a culture of continuous improvement with our performance management tools. Set goals, track progress, and conduct evaluations, driving employee development and overall organizational success."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="computer"
                        title="Real-Time Analytics"
                        description="Harness the power of real-time analytics to gain valuable insights into your workforce. Make data-driven decisions, identify trends, and optimize your HR strategies for maximum impact on business outcomes."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="settings_suggest"
                        title="Customizable Solutions"
                        description="Our HRMS software is designed to adapt to the unique requirements of your business. Enjoy a tailor-made solution that aligns perfectly with your organizational structure and goals."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="scale"
                        title="Scalability"
                        description="Whether you're a startup or an enterprise, our HRMS grows with you. Scale your software usage as your business expands, ensuring you always have the tools you need to support your evolving HR processes."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                </Grid>
              </Grid>
              <MKTypography variant="body1" textAlign="center" opacity={0.8} mt={1} mb={3}>
                Invest in the future of HR management with our user-friendly and efficient HRMS
                software. Let us empower your business to thrive by optimizing your human resource
                processes, allowing you to focus on what truly matters - driving success and growth.
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
      </Card>
    </>
  );
}

export default ServiceHRMS;
