// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";
import AboutUs from "layouts/pages/landing-pages/about-us";
import Presentation from "layouts/pages/presentation";
import ContactUs from "layouts/pages/landing-pages/contact-us";
import LogicErp from "layouts/pages/landing-pages/Logic-Erp";
import EpfEsic from "layouts/pages/landing-pages/ServiceEpfEsic";
import ServiceHRMS from "layouts/pages/landing-pages/ServiceHRMS";
import ServicePayroll from "layouts/pages/landing-pages/ServicePayroll";
import ServiceAccountFinance from "layouts/pages/landing-pages/ServiceAcc-Fin";
import ServiceErp from "layouts/pages/landing-pages/ServiceErp";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import ggLogo from "assets/images/logos/gglogo.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "row Grid",
    punchLine: "Go Easy Grow Easy",
    image: ggLogo,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/CreativeTim/",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/creativetim",
    },
    {
      icon: <GitHubIcon />,
      link: "https://github.com/creativetimofficial",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w",
    },
  ],
  menus: [
    {
      name: "company",
      items: [
        { name: "Home", route: "/home", component: <Presentation /> },
        { name: "About us", route: "/about-us", component: <AboutUs /> },
        { name: "Contact us", route: "/contact-us", component: <ContactUs /> },
      ],
    },
    {
      name: "Products",
      items: [{ name: "Logic Erp", route: "/products/LogicErp", component: <LogicErp /> }],
    },
    {
      name: "Services",
      items: [
        { name: "EPF & ESIC", route: "/services/epf-esic", component: <EpfEsic /> },
        { name: "HRMS Software", route: "/services/hrms", component: <ServiceHRMS /> },
        { name: "Payroll Processing", route: "/services/payroll", component: <ServicePayroll /> },
        {
          name: "Financial & Accounting",
          route: "/services/account&finance",
          component: <ServiceAccountFinance />,
        },
        { name: "ERP Software", route: "/services/erp", component: <ServiceErp /> },
      ],
    },
    {
      name: "Downloads",
      items: [
        {
          name: "EPF Claim form (Non-Adhaar)",
          href: "https://www.epfindia.gov.in/site_docs/PDFs/Downloads_PDFs/Form_CCF_nonaadhar.pdf",
        },
        {
          name: "EPF Claim form (Adhaar)",
          href: "https://www.epfindia.gov.in/site_docs/PDFs/Downloads_PDFs/Form_CCF_aadhar.pdf",
        },
        {
          name: "Pension Settlement form 10D*",
          href: "https://www.epfindia.gov.in/site_docs/PDFs/Downloads_PDFs/Form10D.pdf",
        },
        {
          name: "PF Resistration from 11",
          href: "https://www.epfindia.gov.in/site_docs/PDFs/Downloads_PDFs/Form11Revised.pdf",
        },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} Grow Grid.
    </MKTypography>
  ),
};
