import "./LogicErp.css";
import procurementSvg from "../../../assets/images/LogicErp/procurement.svg";
import OrderProcessing from "../../../assets/images/LogicErp/OrderProcessing.svg";
import WarehouseManagement from "../../../assets/images/LogicErp/WarehouseManagement.svg";
import Production from "../../../assets/images/LogicErp/Production.svg";
import SupplyChain from "../../../assets/images/LogicErp/SupplyChain.svg";
import Payroll from "../../../assets/images/LogicErp/Payroll.svg";
import AccAndFin from "../../../assets/images/LogicErp/AccAndFin.svg";
import MisReporting from "../../../assets/images/LogicErp/MisReporting.svg";
import POS from "../../../assets/images/LogicErp/POS.svg";
import CrmLoyalty from "../../../assets/images/LogicErp/CrmLoyalty.svg";
import B2B from "../../../assets/images/LogicErp/B2B.svg";
import Inventory from "../../../assets/images/LogicErp/inventory.svg";
import Ecom from "../../../assets/images/LogicErp/ecom.svg";
import Campaign from "../../../assets/images/LogicErp/campaign.svg";
import VerticalsLogo from "../../../assets/images/LogicErp/verticalsLogo.svg";
import apparels from "../../../assets/images/LogicErp/apparels.svg";
import fmcg from "../../../assets/images/LogicErp/fmcg.svg";
import pharma from "../../../assets/images/LogicErp/pharma.svg";
import LifeStyle from "../../../assets/images/LogicErp/LifeStyle.svg";
import Electronics from "../../../assets/images/LogicErp/electronics.svg";
import fnb from "../../../assets/images/LogicErp/fnb.svg";
import Wellness from "../../../assets/images/LogicErp/wellness.svg";
import Hardware from "../../../assets/images/LogicErp/hardware.svg";
import VideoPlayIcon from "../../../assets/images/LogicErp/VideoPlayIcon.svg";
import PlayIcon from "../../../assets/images/LogicErp/PlayIcon.svg";
import Carousel from "react-multi-carousel";
import MKBox from "components/MKBox";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import { Link } from "react-router-dom";
import routes from "routes";
import "react-multi-carousel/lib/styles.css";

import { useState } from "react";

// @mui material components
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Material Kit 2 React components
import MKButton from "components/MKButton";
//import MKTypography from "components/MKTypography";

function ProductLogicErp() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const testimonials = [
    {
      YtVideoId: "-Si375k4amU",
    },
    {
      YtVideoId: "MvOXiLBdp44",
    },
    {
      YtVideoId: "DH87XRMdM_U",
    },
    {
      YtVideoId: "m1QTYvbS_Zk",
    },
    {
      YtVideoId: "RWvPquamNmA",
    },
    {
      YtVideoId: "MK1pGZPxXq0",
    },
    {
      YtVideoId: "ziVOL_NnutE",
    },
  ];

  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  const [embedYtVideo, setembedYtVideo] = useState("");

  return (
    <>
      <MKBox top=".5rem" width="100%">
        <DefaultNavbar routes={routes} transparent light />
      </MKBox>
      <div className="container-fluid header py-3 logiclogo-wrapper">
        <div className="container-xxl">
          <div className="row">
            <div>
              <a className="logiclogo">
                <img src="https://www.logicerp.com/Content/Images/logicbrandlogo.png" alt="new" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Banner */}
      <section className="container-fluid banner-wrap py-3  pt-md-4 pb-md-5 position-relative">
        <span className="mask"></span>
        <div className="container-xxl position-relative pt-lg-3 pb-lg-5">
          <div className="row">
            <div className="col-md-6 col-lg-12 d-flex justify-content-center flex-column pe-xl-5">
              <h1 className="bannertitle text-white text-uppercase mb-2 text-center text-md-start">
                30 years of experience
              </h1>
              <h2 className="bannersubtitle text-white text-uppercase text-center text-md-start mb-4">
                Retail <span className="pipe">|</span> Distribution <span className="pipe">|</span>
                Manufacturing
              </h2>
              <h3 className="bannerdesc text-white d-none d-md-block">
                LOGIC ERP has been a pioneer in providing the best in class End to End ERP Solution
                across the nation to organisations of all scales and sizes. Since 1993, we at LOGIC
                ERP have relentlessly pursued perfection by providing a single platform to handle
                complex business operations no matter the domain. Streamlining your business
                operations, adapting to changing business environments and getting real time
                visibility is truly made easy for you with
                <span className="texthighlight">LOGIC ERP Cloud and On-Premise application.</span>
              </h3>
            </div>
          </div>
        </div>
      </section>

      {/* <!--KEY-FEATURE--> */}
      <section className="py-3 py-md-4 py-lg-5">
        <div className="container-xxl py-lg-5">
          <h5 className="text-center mb-2 mb-md-4 mb-lg-5 blockheading">Key Features</h5>
          <div className="row">
            <div className="col-sm-6 col-md-4 col-lg-3 col-xl-4 mb-md-3">
              <div className="featuretitle">
                Robust, Scalable and Flexible ERP Software Solution
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 col-xl-4 mb-md-3">
              <div className="featuretitle">Streamline all your business operations</div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 col-xl-4 mb-md-3">
              <div className="featuretitle">Automate your Supply Chain with LOGIC ERP SCM</div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 col-xl-4 mb-md-3">
              <div className="featuretitle">
                MRP and Production Planning with LOGIC ERP Manufacturing Software
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 col-xl-4 mb-md-3">
              <div className="featuretitle">
                Optimum Inventory Management using LOGIC ERP Auto-Replenishment Module
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 col-xl-4 mb-md-3">
              <div className="featuretitle">
                Cost Management of Raw/Semi-Finished and Finished Goods
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 col-xl-4 mb-md-3">
              <div className="featuretitle">
                Manage Multiple Stores, Warehouses, Point of Sales with LOGIC ERP
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 col-xl-4 mb-md-3">
              <div className="featuretitle">
                Custom Inbuilt Reporting and Dashboards for Advanced Reporting
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 col-xl-4 mb-md-3">
              <div className="featuretitle">
                Online Cloud Based ERP Software for Realtime Information Access
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 col-xl-4 mb-md-3">
              <div className="featuretitle">Available both in Browser and Desktop</div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 col-xl-4 mb-md-3">
              <div className="featuretitle">
                Streamline Omnichannel Retail through one database, one inventory
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!--MODULE-COVERED--> */}
      <section className="py-3 py-md-4 py-lg-5 position-relative modulecontainer">
        <span className="mask maskwhite"></span>
        <div className="container-xxl py-lg-4 position-relative">
          <div className="row g-3">
            <div className="col-12 col-md-4 col-lg-4 col-xl-6">
              <div className="col-lg-12 h-100 p-4 modules-head">
                <span>&nbsp;</span>
                <h5 className="text-center blockheading">Modules Covered</h5>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-4 col-xl-3">
              <div className="col-lg-12 text-center modulewrap">
                <span className="moduleicon d-flex align-items-center justify-content-center">
                  <img src={procurementSvg} alt="Procurement" />
                </span>
                <p className="">Procurement</p>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-4 col-xl-3">
              <div className="col-lg-12 text-center modulewrap">
                <span className="moduleicon d-flex align-items-center justify-content-center">
                  <img src={OrderProcessing} alt="OrderProcessing" />
                </span>
                <p className="">Order Processing</p>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2">
              <div className="col-lg-12 text-center modulewrap">
                <span className="moduleicon d-flex align-items-center justify-content-center">
                  <img src={WarehouseManagement} alt="WarehouseManagement" />
                </span>
                <p className="">Warehouse Management</p>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2">
              <div className="col-lg-12 text-center modulewrap">
                <span className="moduleicon d-flex align-items-center justify-content-center">
                  <img src={Production} alt="Production" />
                </span>
                <p className="">Production</p>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2">
              <div className="col-lg-12 text-center modulewrap">
                <span className="moduleicon d-flex align-items-center justify-content-center">
                  <img src={SupplyChain} alt="Supply Chain Management" />
                </span>
                <p className="">Supply Chain Management</p>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2">
              <div className="col-lg-12 text-center modulewrap">
                <span className="moduleicon d-flex align-items-center justify-content-center">
                  <img src={Payroll} alt="Payroll" />
                </span>
                <p className="">Payroll</p>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2">
              <div className="col-lg-12 text-center modulewrap">
                <span className="moduleicon d-flex align-items-center justify-content-center">
                  <img src={AccAndFin} alt="Accounts & Finance" />
                </span>
                <p className="">Accounts & Finance</p>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2">
              <div className="col-lg-12 text-center modulewrap">
                <span className="moduleicon d-flex align-items-center justify-content-center">
                  <img src={MisReporting} alt="MIS Reporting" />
                </span>
                <p className="">MIS Reporting </p>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2">
              <div className="col-lg-12 text-center modulewrap">
                <span className="moduleicon d-flex align-items-center justify-content-center">
                  <img src={POS} alt="Point of Sale" />
                </span>
                <p className="">Point of Sale</p>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2">
              <div className="col-lg-12 text-center modulewrap">
                <span className="moduleicon d-flex align-items-center justify-content-center">
                  <img src={CrmLoyalty} alt="CRM - Loyalty Management" />
                </span>
                <p className="">CRM - Loyalty Management</p>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2">
              <div className="col-lg-12 text-center modulewrap">
                <span className="moduleicon d-flex align-items-center justify-content-center">
                  <img src={B2B} alt="B2B" />
                </span>
                <p className="">B2B</p>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2">
              <div className="col-lg-12 text-center modulewrap">
                <span className="moduleicon d-flex align-items-center justify-content-center">
                  <img src={Inventory} alt="Inventory Management" />
                </span>
                <p className="">Inventory Management</p>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2">
              <div className="col-lg-12 text-center modulewrap">
                <span className="moduleicon d-flex align-items-center justify-content-center">
                  <img src={Ecom} alt="Ecommerce" />
                </span>
                <p className="">Ecommerce</p>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-3 col-xl-2">
              <div className="col-lg-12 text-center modulewrap">
                <span className="moduleicon d-flex align-items-center justify-content-center">
                  <img src={Campaign} alt="Promotion & Campaign Management" />
                </span>
                <p className=""> Promotion & Campaign Management</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Verticals */}
      <section className="py-3 py-md-4 py-lg-5">
        <div className="container-xxl py-lg-4">
          <div className="row">
            <div className="col-lg-4 d-flex align-items-center justify-content-center pe-lg-5">
              <div className="w-100 text-center enterprise-vertical-heading-wrap">
                <span className="d-none d-md-inline-flex enterprise-vertical-icon">
                  <img src={VerticalsLogo} alt="Verticals" />
                </span>
                <h5 className="blockheading mt-2 mb-3">Verticals </h5>
                <p className="text-center blocksubheading">
                  Vertical Specific and GST Ready Business Application managing Multi-Location,
                  Multi-Company, and Multi-Vertical.
                </p>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="vertical-wrap-main mt-md-0 mt-lg-5">
                <div className="row g-3">
                  <div className="col-6 col-md-4 col-lg-3 mb-2 mb-lg-4 vertical-content">
                    <span className="vertical-icon mb-3">
                      <img src={apparels} alt="Apparel" />
                    </span>
                    <p className="vertical-title">Apparel</p>
                    <p className="vertical-tag">
                      <span>Apparel</span>
                      <span>Footwear</span>
                      <span>Fashion</span>
                    </p>
                  </div>
                  <div className="col-6 col-md-4 col-lg-3 mb-2 mb-lg-4 vertical-content">
                    <span className="vertical-icon mb-3">
                      <img src={fmcg} alt="FMCG" />
                    </span>
                    <p className="vertical-title">FMCG</p>
                    <p className="vertical-tag">
                      <span>FMCG</span>
                      <span>Supermarkets</span>
                      <span>Hypermarkets</span>
                    </p>
                  </div>
                  <div className="col-6 col-md-4 col-lg-3 mb-2 mb-lg-4 vertical-content">
                    <span className="vertical-icon mb-3">
                      <img src={pharma} alt="Pharma" />
                    </span>
                    <p className="vertical-title">Pharma</p>
                    <p className="vertical-tag">
                      <span>Phamaceticals</span>
                      <span>Medicine</span>
                    </p>
                  </div>
                  <div className="col-6 col-md-4 col-lg-3 mb-2 mb-lg-4 vertical-content">
                    <span className="vertical-icon mb-3">
                      <img src={LifeStyle} alt="Lifestyle" />
                    </span>
                    <p className="vertical-title">Lifestyle &amp; Fashion</p>
                    <p className="vertical-tag">
                      <span>Cosmetics</span>
                      <span>jewelery</span>
                      <span>Home Decor</span>
                    </p>
                  </div>
                  <div className="col-6 col-md-4 col-lg-3 mb-2 mb-lg-4 vertical-content">
                    <span className="vertical-icon mb-3">
                      <img src={Electronics} alt="Electronics" />
                    </span>
                    <p className="vertical-title">Electronics</p>
                    <p className="vertical-tag">
                      <span>Electronics</span>
                      <span>Electrical</span>
                      <span>White Goods</span>
                    </p>
                  </div>
                  <div className="col-6 col-md-4 col-lg-3 mb-2 mb-lg-4 vertical-content">
                    <span className="vertical-icon mb-3">
                      <img src={fnb} alt="Food & Beverages" />
                    </span>
                    <p className="vertical-title">F&amp;B</p>
                    <p className="vertical-tag">
                      <span>Food</span>
                      <span>Beverages</span>
                    </p>
                  </div>
                  <div className="col-6 col-md-4 col-lg-3 mb-2 mb-lg-4 vertical-content">
                    <span className="vertical-icon mb-3">
                      <img src={Wellness} alt="Wellness" />
                    </span>
                    <p className="vertical-title">Wellness</p>
                    <p className="vertical-tag">
                      <span>Wellness</span>
                      <span>Spa</span>
                      <span>Salons</span>
                    </p>
                  </div>
                  <div className="col-6 col-md-4 col-lg-3 mb-2 mb-lg-4 vertical-content">
                    <span className="vertical-icon mb-3">
                      <img src={Hardware} alt="Hardware" />
                    </span>
                    <p className="vertical-title"> Hardware</p>
                    <p className="vertical-tag">
                      <span>Hardware</span>
                      <span>Paints</span>
                      <span>Building Material</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!--TESTIMONIAL--> */}
      <section className="py-3 py-md-4 py-lg-5 testimonialWrap position-relative">
        <span className="maskwhite"></span>
        <div className="container-xxl position-relative py-lg-4 pb-xl-5">
          <h5 className="text-center mb-2 mb-md-3 blockheading">Testimonials</h5>
          <p className="text-center blocksubheading mb-3 mb-lg-5 w-75 m-auto">
            We place huge value on strong relationship and have seen the benefit they bring our
            business. Customer feedback is vital helping us to get it right.
          </p>
          <div className="row pt-lg-4">
            <div className="col-lg-12 mb-2">
              <div id="testimonialSlider" className="owl-carousel">
                <Carousel responsive={responsive}>
                  {testimonials.map(({ YtVideoId }, key) => (
                    <div
                      className="d-block position-relative px-3"
                      key={key}
                      data-video={YtVideoId}
                    >
                      <img
                        src={`https://i.ytimg.com/vi/${YtVideoId}/hqdefault.jpg`}
                        className="img-fluid"
                        alt="new"
                      />
                      <span className="popup-youtube video-play-icon d-inline-block">
                        <span className="">
                          <img
                            src={PlayIcon}
                            alt="Play"
                            onClick={() => {
                              toggleModal(true);
                              setembedYtVideo(YtVideoId);
                            }}
                          />
                        </span>
                      </span>
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!--DEMO SECTION--> */}
      <section className="container-fluid  section-demo-wrapper py-4">
        <div className="row align-items-center justify-content-center">
          <div className="col-auto me-xl-1">
            {/* <!--<h5 className="home-demo-title">Let's Try! Get to know more</h5>--> */}
            <p className="home-demo-heading m-0 text-white">
              Get
              <span>Live Demo</span>
            </p>
          </div>
          <div className="col-auto d-flex">
            <Link
              type="button"
              id="goToTopButton"
              className="btn-home-demo btn-home-demo-contact m-2 border-0"
              to="/contact-us"
            >
              Book Now
            </Link>
          </div>
        </div>
      </section>

      {/* <!--MODAL--> */}
      <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
        <Slide direction="down" in={show} timeout={500} id={"YtVideoModel"}>
          <MKBox
            position="relative"
            width="500px"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
          >
            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <img src={VideoPlayIcon} style={{ height: 32, opacity: 0.9 }} alt={embedYtVideo} />
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox p={2}>
              <iframe
                className="videoIframe"
                src={`https://www.youtube.com/embed/${embedYtVideo}`}
                frameBorder={0}
                allowfullscreen
              ></iframe>
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox display="flex" justifyContent="space-between" p={1.5}>
              <MKButton variant="gradient" color="dark" onClick={toggleModal}>
                close
              </MKButton>
            </MKBox>
          </MKBox>
        </Slide>
      </Modal>
    </>
  );
}
export default ProductLogicErp;
