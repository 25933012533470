import React from "react";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import "normalize.css/normalize.css";
import "./slider-animations.css";
import "./Carousel.css";

const content = [
  {
    title: "WHERE INNOVATION MEETS",
    subtitle: "EXPERIENCE",
    description: "FLEXIBLE | SCALABLE | REAL-TIME",
  },
  {
    title: "Cutting edge solutions tailored specifically for your needs",
    description:
      "Unlock the power of innovation. Our solutions are the keys that open doors to the future of business. In the digital age, cutting-edge is not a choice; it's a necessity. Elevate your business with solutions ahead of their time. A world where change is the only constant, our solutions are the catalysts that redefine what's possible in business.",
  },
  {
    title: "Empowering Success Through Innovative IT, Payroll and Financial Services",
    description:
      "Welcome to Growgrid IT Integration & Financial Services Pvt. Ltd, where cutting-edge technology meets financial expertise to drive your business forward. As a leader in the dynamic landscape of IT, Payroll and financial services, we pride ourselves on delivering tailor-made solutions that transcend traditional boundaries.",
  },
];

const Carousel = () => (
  <div className="wrapper">
    <Slider autoplay={3000} className="slider-wrapper">
      {content.map((item, index) => (
        <div key={index} className="slider-content">
          <div className="inner">
            <h1 className="slider-title">{item.title}</h1>
            <p>
              {item.subtitle && (
                <div className="slider-subtitle">
                  <span>{item.subtitle}</span>
                  <br />
                </div>
              )}
              <span className="slider-description">{item.description}</span>
            </p>
          </div>
        </div>
      ))}
    </Slider>
  </div>
);
export default Carousel;
