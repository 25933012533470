/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import "assets/Style.css";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import routes from "routes";

// Image
import bgImage from "assets/images/illustrations/illustration-reset.jpg";
// import { Typography } from "@mui/material";

function ServicePayroll() {
  return (
    <>
      <MKBox top=".5rem" width="100%">
        <DefaultNavbar routes={routes} transparent light />
      </MKBox>
      <MKBox
        minHeight="55vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center", marginTop: "15px" }}
          >
            <MKTypography
              variant="h3"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Simplify Your Payroll, Elevate Your Business:
              <br />
              Choose Our Expert Payroll Services!
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -7,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" py={8} px={20}>
          <Container>
            <Grid container alignItems="center">
              <MKTypography variant="body1" textAlign="center" opacity={0.8} mt={1} mb={3}>
                Managing payroll can be intricate and time-consuming, diverting your attention from
                core business activities. Our dedicated payroll services are tailored to alleviate
                this burden, providing seamless solutions that enhance accuracy, compliance, and
                overall operational efficiency.
              </MKTypography>
              <Grid item xs={12} lg={12}>
                <Grid container justifyContent="flex-start">
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="add_task"
                        title="Accuracy and Compliance"
                        description="Say farewell to payroll errors and compliance concerns. Our experienced professionals ensure precise calculations, tax deductions, and adherence to the latest regulatory requirements, minimizing the risk of financial penalties."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="more_time"
                        title="Time Savings"
                        description="Reclaim valuable time by outsourcing your payroll responsibilities to us. Our streamlined processes and advanced software enable swift and efficient payroll management, allowing you to focus on strategic business initiatives."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="currency_rupee"
                        title="Tax Efficiency"
                        description="Navigate the complexities of payroll taxes effortlessly. We stay abreast of tax regulations, deductions, and credits, ensuring your business takes advantage of available opportunities while remaining fully compliant."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="security"
                        title="Secure Data Handling"
                        description="Your payroll data is handled with the utmost security. Our systems employ robust encryption and compliance with data protection standards, safeguarding sensitive information and maintaining confidentiality."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="self_improvement"
                        title="Employee Self-Service Portals"
                        description="Empower your employees with convenient self-service portals. From accessing pay stubs to updating personal information, our user-friendly platforms enhance employee satisfaction and reduce administrative burdens."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="summarized"
                        title="Customized Reporting"
                        description="Gain valuable insights into your payroll data with customized reports. Track expenses, analyze trends, and make informed decisions with our comprehensive reporting tools, tailored to meet your specific needs."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="scale"
                        title="Scalable Solutions"
                        description="Whether you're a small business or a large enterprise, our payroll services scale to accommodate your growth. Enjoy the flexibility to adapt our solutions to your evolving business requirements."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKBox mb={5}>
                      <DefaultInfoCard
                        icon="support_agent"
                        title="Comprehensive Support"
                        description="Our dedicated support team is always ready to assist. From addressing inquiries to resolving issues promptly, we ensure a smooth payroll experience for you and your employees."
                        descriptionType="Text"
                      />
                    </MKBox>
                  </Grid>
                </Grid>
              </Grid>
              <MKTypography variant="body1" textAlign="center" opacity={0.8} mt={1} mb={3}>
                Outsource your payroll management to us, and witness the transformation of a once
                complex process into a streamlined, worry-free operation. Let us handle the
                intricacies, so you can focus on propelling your business to new heights.
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
      </Card>
    </>
  );
}

export default ServicePayroll;
