/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function Information() {
  return (
    <MKBox component="section" py={8} px={8}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={12}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="Why choose us?"
                    description="Where Choice Meets Success.|Precision, Passion, and Proven Results.|High Customer Satisfaction.|Quality Service.|Transparent Process."
                    descriptionType="List"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={12}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="flag"
                    title="Our mission"
                    description="Imagine a workplace where employees come to work inspired, feel safe, share a common purpose with peers and leaders, do their best work, and go home fulfilled. We are on a mission to make this happen across the world."
                    descriptionType="Text"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={12}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="visibility"
                    title="Our vision"
                    description="Just like you, we were a growing organization that needed a people platform to scale our services business. We evaluated every software in the market and were frustrated after reviewing all of them. Thus, we started GrowGrid. The goal was simple. Automate and simplify people processes so you can focus on your core asset - the employees. They, in turn, will transform you from a good to a great organization!"
                    descriptionType="Text"
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={5} sx={{ ml: "auto" }}>
            <CenteredBlogCard
              image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              title="We are here to support you"
              description="We promise prompt service and support to you because we want to be there and journey with you through every moment of managing and building your business."
              action={{
                type: "internal",
                route: "/contact-us",
                color: "info",
                label: "book a demo",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
