/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components for modal
import * as React from "react";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import Container from "@mui/material/Container";
import MKBox from "components/MKBox";
import Input from "@mui/material/Input";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import "assets/Style.css";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Icons
import MapIcon from "@mui/icons-material/Map";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

// Routes
import routes from "routes";

// Image
import bgImage from "assets/images/illustrations/illustration-reset.jpg";
// import { Typography } from "@mui/material";

function ContactUs() {
  const [show, setShow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toggleModal = () => setShow(!show);
  const formData = {
    Name: "",
    Phone: "",
    Email: "",
    Message: "",
  };

  const ResetFormData = () => {
    formData.Name = "";
    formData.Phone = "";
    formData.Email = "";
    formData.Message = "";

    document.getElementById("txtFullName").value = "";
    document.getElementById("txtEmail").value = "";
    document.getElementById("txtMobile").value = "";
    document.getElementById("txtMessage").value = "";
  };

  const [formSubmitStatusMessage, setSubmitMessage] = useState({
    Title: "",
    Message: "",
  });

  const UpdateFullName = () => {
    var data = document.getElementById("txtFullName").value;
    formData.Name = data;
  };

  const UpdateEmail = () => {
    var data = document.getElementById("txtEmail").value;
    formData.Email = data;
  };

  const UpdateMobile = () => {
    var data = document.getElementById("txtMobile").value;
    formData.Phone = data;
  };

  const UpdateMessage = () => {
    var data = document.getElementById("txtMessage").value;
    formData.Message = data;
  };

  const ValidateFormData = () => {
    var errorMsg = "";
    var hasError = false;
    if (
      formData.Name.trim() == null ||
      formData.Name.trim() == undefined ||
      formData.Name.trim() == ""
    ) {
      hasError = true;
      errorMsg += "Name was not enterted. ";
    }
    if (
      formData.Email.trim() == null ||
      formData.Email.trim() == undefined ||
      formData.Email.trim() == ""
    ) {
      hasError = true;
      errorMsg += "Email was not entered or invalid. ";
    }
    if (
      formData.Phone.trim() == null ||
      formData.Phone.trim() == undefined ||
      formData.Phone.trim() == ""
    ) {
      hasError = true;
      errorMsg += "Phone / Mobile number was not entered. ";
    }
    if (
      formData.Message.trim() == null ||
      formData.Message.trim() == undefined ||
      formData.Message.trim() == ""
    ) {
      hasError = true;
      errorMsg += "Message was empty. ";
    }
    setSubmitMessage({
      Title: "Following Error(s) were detected!",
      Message: errorMsg,
    });
    return !hasError;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (ValidateFormData()) {
        setIsSubmitting(true);
        await axios.post(
          "https://script.google.com/macros/s/AKfycbzzZj7MmCIXjtFyfplsLJ0G1rpVWqkK6OryGcRM1b0rwkfVJqPBaSgC0Oy-76AhCtni/exec",
          formData,
          { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
        );
        setSubmitMessage({
          Title: "Success!",
          Message:
            "Thanks for showing interest!!! Sit back and relax, we will connect to you soon.",
        });
      }
    } catch (error) {
      setSubmitMessage({
        Title: "Sincere Apologies!",
        Message: "There is an error while submitting data, we are working on to fix this.",
      });
    }
    toggleModal(true);
    ResetFormData();
    setIsSubmitting(false);
  };

  return (
    <>
      <MKBox top=".5rem" width="100%">
        <DefaultNavbar routes={routes} transparent light />
      </MKBox>
      <MKBox
        minHeight="55vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center", marginTop: "15px" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              First Class Services is what you get.
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              Our implementation process includes: Pre-implementation Discussion, Data Migration,
              Training, Post-implementation discussion, Hand-hold support until implementation goes
              live.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -7,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid container spacing={4} py={6} alignItems={"flexstart"}>
          <Grid item xs={12} lg={6}>
            <MKBox ml={5.5}>
              <MKBox mb={5}>
                <DefaultInfoCard
                  // icon="payments"
                  title="Contact Information"
                  // description="We get insulted by others, lose trust for those We get back freezes"
                />
              </MKBox>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <MapIcon fontSize="large" sx={{ color: "#1A73E8" }} />
                <MKTypography variant="body2" opacity={0.8} mr={{ md: 32 }} pl={{ md: 4, xs: 4 }}>
                  <a href="#contact" style={{ color: "grey" }}>
                    U-66, Rukmani Tower, Upadhyay Block, Shakarpur, Laxmi Nagar, Delhi-110092
                  </a>
                </MKTypography>
              </div>
              <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                <PhoneIcon fontSize="large" sx={{ color: "#1A73E8" }} />
                <MKTypography variant="body2" opacity={0.8} pl={4} mr={32}>
                  <a href="to:9899845664" style={{ color: "grey", width: "100vw" }}>
                    9899845664
                  </a>
                  <br />
                  <a href="to:8418075148" style={{ color: "grey" }}>
                    8418075148
                  </a>
                  <br />
                  <a href="to:01145424554" style={{ color: "grey" }}>
                    011-45424554
                  </a>
                </MKTypography>
              </div>
              <div style={{ display: "flex", marginTop: "20px", alignItems: "center" }}>
                <EmailOutlinedIcon fontSize="large" sx={{ color: "#1A73E8" }} />
                <MKTypography variant="body2" opacity={0.8} pl={4} mr={32}>
                  <a href="mailto:enquiry@growgrid.in" style={{ color: "grey", width: "100vw" }}>
                    enquiry@growgrid.in
                  </a>
                  <br />
                  <a href="mailto:support@growgrid.in" style={{ color: "grey" }}>
                    support@growgrid.in
                  </a>
                </MKTypography>
              </div>
            </MKBox>
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            md={7}
            lg={6}
            xl={4}
            ml={{ xs: "auto", lg: 6 }}
            mr={{ xs: "auto", lg: 6 }}
          >
            <MKBox
              bgColor="white"
              width={{ md: 675 }}
              borderRadius="xl"
              shadow="lg"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              mt={{ xs: 20, sm: 18, md: 0 }}
              // mb={{ xs: 20, sm: 18, md: 20 }}
              mx={{ xs: 3, sm: 18, md: -25 }}
            >
              <MKBox
                variant="gradient"
                bgColor="info"
                coloredShadow="info"
                borderRadius="lg"
                p={2}
                mx={2}
                // mt={-3}
              >
                <MKTypography variant="h3" color="white" textAlign="center">
                  CONTACT US FOR DEMO
                </MKTypography>
              </MKBox>
              <MKBox p={3}>
                <MKBox width="100%" component="form" method="post" autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Input
                        id="txtFullName"
                        onBlur={UpdateFullName}
                        variant="standard"
                        placeholder="FullName"
                        Name="FullName"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Input
                        id="txtMobile"
                        placeholder="Mobile"
                        onBlur={UpdateMobile}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        id="txtEmail"
                        type="email"
                        placeholder="Email"
                        onBlur={UpdateEmail}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        id="txtMessage"
                        onBlur={UpdateMessage}
                        placeholder="How can we help you?"
                        InputLabelProps={{ shrink: true }}
                        multiline
                        fullWidth
                        rows={6}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                    <MKButton
                      variant="gradient"
                      disabled={isSubmitting}
                      color="info"
                      onClick={handleSubmit}
                    >
                      {isSubmitting ? "Sending..." : "Send Message"}
                    </MKButton>
                  </Grid>
                </MKBox>
              </MKBox>
            </MKBox>
          </Grid>
        </Grid>
      </Card>
      <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
        <Slide direction="down" in={show} timeout={500}>
          <MKBox
            position="relative"
            width="500px"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
          >
            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5">{formSubmitStatusMessage.Title}</MKTypography>
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox p={2}>
              <MKTypography variant="body2" color="secondary" fontWeight="regular">
                {formSubmitStatusMessage.Message}
              </MKTypography>
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox display="flex" justifyContent="space-between" p={1.5}>
              <MKButton variant="gradient" color="dark" onClick={toggleModal}>
                close
              </MKButton>
            </MKBox>
          </MKBox>
        </Slide>
      </Modal>
    </>
  );
}

export default ContactUs;
